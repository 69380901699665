.theme-finans {
	--theme-color-white: var(--color-white);
	--theme-color-neutral-50: var(--color-grey-50);
	--theme-color-neutral-100: var(--color-grey-100);
	--theme-color-neutral-200: var(--color-grey-200);
	--theme-color-neutral-300: var(--color-grey-300);
	--theme-color-neutral-350: var(--color-grey-350);
	--theme-color-neutral-400: var(--color-grey-400);
	--theme-color-neutral-450: var(--color-grey-450);
	--theme-color-black: var(--color-black);
    --theme-color-advertorial: var(--color-advertorial-blue);

    --theme-color-article-teaser-kicker: var(--theme-color-primary);
	--theme-color-cta: var(--color-finans-cta-red);
	--theme-color-cta-hover: var(--color-finans-cta-red-hover);
	--theme-color-logo-primary: var(--theme-color-black);
	--theme-color-primary: var(--color-finans-red);
	--theme-color-primary-hover: var(--color-finans-red-hover);
	--theme-color-mono: var(--theme-color-neutral-450);
	--theme-color-mono-hover: var(--theme-color-black);
	--theme-color-warning: var(--color-finans-red);
	--theme-color-warning-hover: var(--color-finans-red-hover);

	--theme-font-article-body: var(--font-family-open-sans);
	--theme-font-article-heading: var(--font-family-trola);
	--theme-font-article-marker: var(--font-family-trola);
	--theme-font-generic-body: var(--font-family-open-sans);
	--theme-font-generic-label: var(--font-family-open-sans);
	--theme-font-sponsored-content: var(--font-family-hind);

	--theme-spacing-button-text-vertical-alignment-correction: 0;
	--theme-spacing-header-logo-height: 1.625rem;
	--theme-spacing-header-logo-vertical-alignment-correction: 0;
}
